export const data = [
    {
        "position": 1,
        "videoId": "h198d755om",
        "videoTitle": "Idiopathic Hypersomnia",
        "videoDescription": "What exactly is Idiopathic Hypersomnia? What are the symptoms? How is it diagnosed? Get the answers to these questions and hear what it's like living with Idiopathic Hypersomnia from a real patient.",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/f8380894f205a2e3da63461deb1eb037.png",
        "speakerName": "Name",
        "speakerTitle": "Title",
        "videoTranscript": [
            { "paragraph": "Welcome to our Jazz Pharmaceuticals Webinar. Today, we’ll discuss idiopathic hypersomnia, or IH." },
            { "paragraph": "Chapter 1: Idiopathic Hypersomnia and Its Symptoms \n What Idiopathic Hypersomnia (IH) is Its Symptoms" },
            { "paragraph": "Chapter 2: Diagnosis and \r How it is Diagnosed The Impact of Idiopathic Hypersomnia (IH) " },
            { "paragraph": "During today’s presentation, we’ll learn about: What idiopathic hypersomnia, or IH, is Its symptoms" },
            { "paragraph": "There are a couple of topics we won’t be able to address today, such as: \r Any particular idiopathic hypersomnia (IH) symptoms you may have Treatments for idiopathic hypersomnia (IH)" },
            { "paragraph": " \r  It’s a rare central sleep disorder.  “Idiopathic” means we don’t know what causes it “Hypersomnia” is a condition that causes you to feel very sleepy during the day, and/or makes you need excessive amounts of sleep  “Central” means it is neurologic, or related to how your brain works   “Sleep disorder” means there is a problem with the quality, timing, or amount of sleep you get" },
            { "paragraph": "Let’s hear what it’s like to live with idiopathic hypersomnia from a person who actually has the condition." },
            { "paragraph": "Let’s look at the symptoms. People with idiopathic hypersomnia (IH): \r Have excessive daytime sleepiness (EDS), meaning they feel very sleepy during the day  Tend to not feel rested (refreshed) when they wake up, no matter how much they’ve slept  Commonly have sleep inertia, which is also called sleep drunkenness. This means it’s very difficult for them to wake up, and they feel groggy and disoriented when they do wake up. This occurs in nearly half of people with idiopathic hypersomnia (IH). This can be so severe for some people with idiopathic hypersomnia (IH) that they may need multiple alarm clocks or even the help of another person to get them out of bed. Some people with idiopathic hypersomnia (IH) say that their sleep inertia makes waking up one of the hardest things they need to do all day" },
            { "paragraph": "Here are some other common symptoms. People with idiopathic hypersomnia (IH): \r May take long naps during the day. However, because naps tend to be unrefreshing for people living with idiopathic hypersomnia (IH), they can make some people with idiopathic hypersomnia (IH) feel even worse. We know that many people with idiopathic hypersomnia (IH) try to avoid napping altogether, but not everyone is able to do this  Have brain fog. While brain fog is not unique to idiopathic hypersomnia (IH), it is common. People living with idiopathic hypersomnia (IH) will often report problems thinking clearly and accomplishing basic tasks  Are able to sleep for many hours of the day. Some people with idiopathic hypersomnia (IH) can sleep for much longer than normal. For some people with idiopathic hypersomnia (IH), 11 hours or more is typical" },
            { "paragraph": "It can take a lot of persistence to get a diagnosis of idiopathic hypersomnia. Let’s hear one person’s story." },
            { "paragraph": "Getting an idiopathic hypersomnia (IH) diagnosis can be a frustrating journey and can take a long time. For some people it can take up to 15 years. One potential reason is that excessive daytime sleepiness (EDS) is a symptom in many other conditions. In fact, the sleepiness of idiopathic hypersomnia (IH) may be misdiagnosed as or confused with: \r Obstructive sleep apnea \r Depression \r Narcolepsy with cataplexy (or narcolepsy type 1)" },
            { "paragraph": "A cataplexy attack is when your muscles suddenly go weak or limp when you feel a strong emotion like embarrassment or laughter. People with idiopathic hypersomnia (IH) do not have cataplexy  Narcolepsy without cataplexy (or narcolepsy type 2)  Other psychological/psychiatric problems People with idiopathic hypersomnia (IH) usually begin to develop symptoms in their mid-to-late teens or early 20s. Symptoms may also begin in childhood or at a later age. Idiopathic hypersomnia (IH) symptoms can also change over time. How severe or bothersome they are may not stay the same. " },
            { "paragraph": "Many people want to know how idiopathic hypersomnia (IH) is different from narcolepsy. \r Both narcolepsy type 1 and type 2 are associated with EDS. However, people with either type of narcolepsy tend to feel refreshed after a short nap. People with idiopathic hypersomnia (IH) generally do not \r People with narcolepsy type 1 have cataplexy, as we mentioned previously, while people with narcolepsy type 2 do not have cataplexy \r People with narcolepsy type 1 also tend to have low levels of a specific brain chemical called hypocretin, which helps regulate sleep and wakefulness. People with idiopathic hypersomnia (IH) have normal levels of hypocretin \r So as you can see, although narcolepsy and idiopathic hypersomnia (IH) are sleep disorders, there are significant differences between the 2 conditions." },
            { "paragraph": "So how is idiopathic hypersomnia (IH) diagnosed? \r There are no known blood tests or chemical tests to identify idiopathic hypersomnia (IH). Certain tests called sleep studies are recommended to confirm the diagnosis. These tests are performed overnight at a sleep laboratory by a sleep specialist. \r Polysomnogram, or PSG: \r This is also called an overnight sleep study. It measures the amount and stages of your sleep \r A sleep lab technologist places sensors on the person’s scalp, chest, and legs \r As the person sleeps, a computer records his or her brain waves, blood oxygen level, heart rate, breathing, and eye and leg movements \r Multiple sleep latency test (MSLT): \r This is also called a nap test. It takes place in the daytime, usually immediately following your PSG It consists of five 20-minute nap tests spread throughout the day. Each nap test is separated by 2-hour breaks It tests for EDS by measuring a person’s sleep latency, that is, how fast a person falls asleep during each nap test It also measures whether you go into rapid eye movement (REM) sleep during the nap tests The MSLT of someone with idiopathic hypersomnia (IH) will generally show a mean sleep latency of less than 8 minutes, on average. Your mean sleep latency is the average time it took you to fall asleep across all 5 nap tests" },
            { "paragraph": "It’s important to understand that idiopathic hypersomnia (IH) is more than just being sleepy. The excessive tiredness and mental fatigue can cause significant challenges in work, school, and personal relationships.  Idiopathic hypersomnia (IH) can make it hard to feel independent, creating a sense of dependence on other people. Some people with idiopathic hypersomnia (IH) are not able to wake up by themselves, have difficulty holding a job or continuing school, and are not able to drive (or choose not to)." },
            { "paragraph": "Idiopathic hypersomnia (IH) also affects families, friends, and coworkers of people with the condition. Many people with idiopathic hypersomnia (IH) struggle to wake up at night to care for young or sick children; can’t help with family routines, like getting children ready for school or doing household chores; aren’t as productive as they know they could be at work; and lose time with family or friends because of their condition.  Idiopathic hypersomnia (IH) is also often misunderstood and/or stigmatized and may be seen as being lazy or unmotivated. Idiopathic hypersomnia (IH) is not something someone can “just get over.” " },
            { "paragraph": "So to wrap it all up, what are our key takeaways from this presentation?" },
            { "paragraph": "Let's summarize what we've learned... \r Idiopathic hypersomnia (IH) is a rare sleep disorder that involves the central nervous system \r Idiopathic hypersomnia (IH) is diagnosed with 2 kinds of sleep studies called the PSG and MSLT \r The impact of idiopathic hypersomnia (IH) goes beyond just feeling sleepy " },
            { "paragraph": "Let’s look at some potential next steps." },
            { "paragraph": "If you think you may have idiopathic hypersomnia (IH) but haven’t been tested yet, see a sleep specialist \r  If you’ve been diagnosed with idiopathic hypersomnia (IH), ask your doctor if there are any new medications indicated for that condition \r  Keep up with the latest research about idiopathic hypersomnia (IH). Go to LivingWithIH.com and sign up on the Get Updates page" },
        ],
        "videoResource": [
            {
                "resourceTitle": "Idiopathic Hypersomnia (IH) 101",
                "resourceDescription": "Get the fundamentals on what IH is, including the definition and the key symptoms.",
                "resourceLinkName": "LEARN MORE ABOUT IH",
                "resourceLink": "https://www.livingwithih.com/what-is-idiopathic-hypersomnia"
            },
            {
                "resourceTitle": "IH Symptoms can mean more than feeling sleepy during the day",
                "resourceDescription": "Answer questions to uncover ways IH may be affecting you.",
                "resourceLinkName": "TAKE THE QUIZ",
                "resourceLink": "https://www.livingwithih.com/sleep-quiz"
            },
            {
                "resourceTitle": "Make the most out of your next appointment",
                "resourceDescription": "Whether you've been diagnosed with IH or suspect you may have it, it's important that you let your doctor know about all the struggles you may be going through—in the morning, throughout the day and evening, and with your nighttime sleep.",
                "resourceLinkName": "TALK TO YOUR DOCTOR",
                "resourceLink": "https://www.livingwithih.com/idiopathic-hypersomnia-doctor-conversation-starter"
            }
        ]
    },
    {
        "position": 2,
        "videoId": "6frr962xe2",
        "videoTitle": "Navigating Life With Idiopathic Hypersomnia",
        "videoDescription": "Get tips from an expert to help you manage your life with IH, including how to track the multiple symptoms of IH and talk to your doctor, friends, family, or coworkers about this condition.",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/cc58389b6ebc1811103d6fad3be2dbbf.webp",
        "speakerName": "Anne Marie Morse, DO",
        "speakerTitle": "Clinical Associate Professor at Geisinger Commonwealth School of Medicine",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Need help managing your IH symptoms?",
                "resourceDescription": "Check out these tools to help you and your doctor understand and monitor your symptoms.",
                "resourceLinkName": "VIEW TOOLS",
                "resourceLink": "https://www.livingwithih.com/idiopathic-hypersomnia-symptoms-tools"
            },
            {
                "resourceTitle": "Make the most out of your next appointment",
                "resourceDescription": "Whether you've been diagnosed with IH or suspect you may have it, it's important that you let your doctor know about all the struggles you may be going through—in the morning, throughout the day and evening, and with your nighttime sleep.",
                "resourceLinkName": "TALK TO YOUR DOCTOR",
                "resourceLink": "https://www.livingwithih.com/idiopathic-hypersomnia-doctor-conversation-starter"
            },
            {
                "resourceTitle": "Looking for a doctor who treats IH?",
                "resourceDescription": "Find a sleep specialist near you who understands and treats this condition. ",
                "resourceLinkName": "FIND A SPECIALIST",
                "resourceLink": "https://www.livingwithih.com/idiopathic-hypersomnia-doctor-conversation-starter#patient-and-doctor-perspective"
            }
        ]
    },
    {
        "position": 3,
        "videoId": "eim6xv3jx9",
        "videoTitle": "Nighttime Sleep With Idiopathic Hypersomnia",
        "videoDescription": "You may feel your IH symptoms most during the day. But did you know that unrefreshing nighttime sleep could be part of the problem? Find useful tools to help monitor the multiple symptoms of IH so you can share what you’re experiencing with your doctor.",
        "thumbnail": "https://embed-ssl.wistia.com/deliveries/60d19462c826aea09c62a8e75f73026d.webp",
        "speakerName": "Asim Roy, MD",
        "speakerTitle": "Medical Director of the Ohio Sleep Medicine Institute",
        "videoTranscript": [
        ],
        "videoResource": [
            {
                "resourceTitle": "Could your nighttime sleep be playing a role in your IH? ",
                "resourceDescription": "While you may feel your IH symptoms most during the day, the kind of sleep you get at night may be part of the problem.",
                "resourceLinkName": "UNDERSTAND YOUR IH",
                "resourceLink": "https://www.livingwithih.com/possible-causes-of-idiopathic-hypersomnia"
            },
            {
                "resourceTitle": "Did you know there’s more than one way to manage IH?",
                "resourceDescription": "Learn more about different ways, including taking treatment.",
                "resourceLinkName": "Managing IH",
                "resourceLink": "https://www.livingwithih.com/managing-idiopathic-hypersomnia"
            },
            {
                "resourceTitle": "Looking for a doctor who treats IH?",
                "resourceDescription": "Find a sleep specialist near you who understands and treats this condition. ",
                "resourceLinkName": "FIND A SPECIALIST",
                "resourceLink": "https://www.livingwithih.com/idiopathic-hypersomnia-doctor-conversation-starter#patient-and-doctor-perspective"
            }
        ]
    }
]