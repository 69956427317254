import React, { useState, useEffect, useContext } from 'react';
import Layout from '@components/structure/Layout';
import { alpha, Tab, Tabs, styled, Grid, Box } from '@mui/material';
import SessionInfoCard from '@mui-components/sessionInfoCard.jsx';
import CtaButton from '@mui-components/ctaButton.jsx';
import OnDemandVideo from '@components/structure/OnDemandVideo.jsx';
import Filters from '@mui-components/filters.jsx';
import Seo from '@components/utility/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import { SlowReveal } from '../../components/utility/Animations';
import { data } from '../../data/ihUnbranded';
import { Api } from '@components/utility/Api';
import { navigate } from 'gatsby';
import Preloader from '@components/structure/Preloader';
import { AppContext } from '@context';

const IhUnbrandedHomePage = ({ location }) => {
	const GROUP = 'unbranded';
	const CONDITION = 'unbrandedih';

	const { selectedWebinars, setSelectedWebinars } = useContext(AppContext);

	const StyledTabs = styled((props) => (
		<Tabs
			{...props}
			TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
		/>
	))({
		height: 50,
		'& .MuiTabs-flexContainer': {
			justifyContent: 'center',
		},
		'& .MuiTabs-indicator': {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: 'transparent',
		},
		'& .MuiTabs-indicatorSpan': {
			// maxWidth: 500,
			width: '100%',
			backgroundColor: 'transparent',
		},
	});
	const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
		({ theme }) => ({
			height: 70,
			maxWidth: '500px',
			width: '100%',
			textTransform: 'none',
			fontWeight: theme.typography.fontWeightRegular,
			fontSize: theme.typography.pxToRem(22),
			marginRight: theme.spacing(0.2),
			color: '#3D3935',
			backgroundColor: alpha('#ffffff', 0.8),
			'&.Mui-selected': {
				color: '#158DA5',
				backgroundColor: alpha('#ffffff', 1),
			},
			':hover': {
				textDecoration: 'none',
			},
		})
	);
	function TabPanel(props) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role='tabpanel'
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}>
				{value === index && (
					<Box sx={{ p: 1 }} md={{ p: 0 }}>
						{children}
					</Box>
				)}
			</div>
		);
	}

	const [value, setValue] = useState(0);
	const [error, setError] = useState(false);
	const [webinarData, setWebinarData] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const userSelectedWebinars = (selected, UUID, Topic) => {
		let session = selectedWebinars;
		let totalSelected = 0;

		if (selected) {
			webinarData.forEach((item) => {
				if (item.Topic === Topic) {
					item.Sessions.forEach((sessionItem) => {
						if (UUID === sessionItem.UUID) {
							let tempData = {};
							tempData.UUID = UUID;
							tempData.Topic = item.Topic;
							tempData.ShortDescription = item.ShortDescription;

							item.Sessions.forEach((sessionItem) => {
								if (sessionItem.UUID === UUID) {
									tempData.Date = sessionItem.Date;
								}
							});
							session[Topic] = tempData;
						}
					});
				}
			});
		} else {
			session[Topic] = '';
			session = Object.fromEntries(
				Object.entries(session).filter((item) => item[0] !== Topic)
			);
		}

		Object.entries(selectedWebinars).map((item, key) => {
			if (item[1]) totalSelected += 1;
		});

		if (totalSelected >= 2) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}

		setSelectedWebinars(session);
	};

	function onRegisterClick(event) {
		let isValid = false;

		if (!webinarData) {
			return;
		}

		if (selectedWebinars) {
			webinarData.map((item, key) => {
				if (selectedWebinars[item.Topic]) {
					isValid = true;
				}
			});
		}

		if (isValid) {
			navigate('register');
		} else {
			setError(true);
			const errorLabel = document.querySelector('.error-label');
			errorLabel.focus();
		}
	}

	useEffect(() => {
		if (location.state && location.state.onDemandTabSelected) {
			setValue(1);
		}

		window.addEventListener('scroll', SlowReveal);

		const success = (response) => {
			setWebinarData(response);
			console.log(response);
		};
		const fail = (error) => {
			console.log('Internal Error ', error);
		};
		Api.get_webinar(success, fail, GROUP, CONDITION);
	}, []);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)

		if(urlParams.get("tab") === "ondemand") {
			setValue(1)
		}
	}, [])

	return (
		<>
			<Layout
				pageType='ih-unbranded'
				className='idiopathicHypersomniaUnbranded'
				pixelCode='PageView'>
				<Seo
					title='Idiopathic Hypersomnia Webinars | Jazz Webinars'
					description='Learn how to manage, treat, and take command of idiopathic hypersomnia (IH). Jazz Webinars brings you the latest info through live, interactive webinars and on-demand videos delivered to you by experts in sleep medicine.'
				/>
				<div>
					{/* <StaticImage
                        className='test-desktop'
                        src='../../../static/images/ih-unbranded-hero.png'
                        alt='Idiopathic hypersomnia Hero Banner Desktop on homepage'
                    /> */}
					<img
						className='test-desktop'
						src='/images/UNBRANDED_IH_compressed_photoshop.jpg'
						alt='Idiopathic hypersomnia Hero Banner Desktop on homepage'
					/>
					<StaticImage
						className='test-mobile '
						src='../../../static/images/ih-unbranded-hero-mobile.png'
						alt='Idiopathic hypersomnia Hero Banner Mobile on homepage'
					/>
					<section className='container firstSection'>
						<Grid container>
							<Grid item xs={12}>
								<div className='lv-copy fade-in-move-up'>
									<h1>
										<span>Stay up-to-date on</span>
										The Symptoms <br className='desktopOnly' />
										and Impact of <br className='desktopOnly' />
										Idiopathic <br className='desktopOnly' />
										Hypersomnia (IH)
									</h1>
								</div>
							</Grid>
						</Grid>

						<div className='styledTabs'>
							<Box sx={{ flexGrow: 1 }}>
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<StyledTabs
											className='tabButtons'
											value={value}
											onChange={handleChange}
											aria-label='styled tabs example'>
											<StyledTab label='Live Webinars' />
											<StyledTab
												label={
													<span>
														Webinars{' '}
														<br className='mobileOnly' />
														On Demand
													</span>
												}
											/>
										</StyledTabs>
									</Grid>
								</Grid>
							</Box>
						</div>
					</section>
				</div>

				{!value ? (
					<section className='smaller-container'>
						<h2 className='center'>
							Information on Idiopathic Hypersomnia can be hard to come by.
						</h2>

						<p>
							<b>Don't worry though, we're here to help.</b> Tune in to
							these live webinars to learn from and interact with doctors
							that specialize in sleep medicine on the symptoms, impact, and
							complexities of living with and caring for individuals with
							this sleep disorder.
						</p>

						<div className='gradient-divider center'>
							<hr />
						</div>
					</section>
				) : (
					<br />
				)}

				<section className='container secondSection pageContent'>
					<TabPanel value={value} index={0}>
						<div className='sessionHeader'>
							{webinarData && webinarData.length ? (
								<h3>
									Select up to 2 sessions that you'd like to attend:
								</h3>
							) : null}
							{webinarData && webinarData.length ? (
								<Grid container>
									<Grid item xs={12} md={1}>
										{/* <p className='filter-heading'>FILTERS: </p> */}
									</Grid>
									<Grid item xs={12} md={8}>
										<div className='filters'>
											{/* <Filters
												className="narcolepsy-branded-filters magenta"
												filters={[
													'Topic Choice',
													'Topic Choice',
													'Topic Choice',
													'Topic Choice',
												]}
											/> */}
										</div>
									</Grid>
									<Grid item xs={12} md={3}>
										<CtaButton
											title='Register Now'
											class='cta link-cta registerBtn btn-transition-ihUnbranded'
											click={onRegisterClick}
										/>
									</Grid>
								</Grid>
							) : null}

							<p
								className={!error ? 'error-label hidden' : 'error-label'}
								tabIndex={-1}>
								<strong>
									To proceed, select at least one of the live webinar
									sessions available.
								</strong>
							</p>
						</div>
						<div className='sessionInfo'>
							<div className='SessionInfoCard'>
								{webinarData ? (
									webinarData.map((item, key) => (
										<SessionInfoCard
											id={key}
											group={item.Topic}
											header={item.Topic}
											leftCopy={
												item.ShortDescription ||
												'No description provided'
											}
											radioLabelCopy='Next Available Sessions'
											name={item.Name}
											selected={
												Object.entries(selectedWebinars).length &&
												selectedWebinars[item.Topic]
													? selectedWebinars[item.Topic].UUID
													: null
											}
											radioItems={item.Sessions}
											iconObj={{
												image:
													item.Topic ===
													'Nighttime Sleep in Idiopathic Hypersomnia (IH)'
														? '/images/icon-sleep.svg'
														: '/images/icon-life.svg',
												width: '125',
												height: '125',
												alt: 'Icon',
												placeholder: '',
											}}
											disabled={
												isDisabled &&
												!selectedWebinars[item.Topic]
													? true
													: false
											}
											functionCall={userSelectedWebinars}
										/>
									))
								) : (
									<Preloader />
								)}
								{webinarData != null && webinarData.length === 0 ? (
									<div className='comingSoonBorder centerText'>
										<p className='comingSoonHeader'>
											<strong>Coming Soon!</strong>
										</p>
										<p>
											Check back at a later date for live webinars.
										</p>
									</div>
								) : null}
							</div>
							{webinarData && webinarData.length ? (
								<CtaButton
									title='Register Now'
									class='cta link-cta registerBtn btn-transition-ihUnbranded '
									click={onRegisterClick}
								/>
							) : null}
							<div className='whiteSpace'></div>
						</div>
					</TabPanel>

					<TabPanel value={value} index={1}>
						<div className='sessionHeader'>
							<h3 className='center'>
								Watch these educational webinars
								<br />
								wherever you want, whenever you want.
							</h3>
							<Grid container>
								{/* <Grid item xs={12} md={1}>
										<p className='filter-heading'>FILTERS: </p>
									</Grid>
									<Grid item xs={12} md={11}>
										<div className="filters">
											<Filters
												className="narcolepsy-branded-filters ihUnbranded"
												filters={[
													'Topic Choice',
													'Topic Choice',
													'Topic Choice',
													'Topic Choice',
												]}
											/>
										</div>
									</Grid> */}
							</Grid>
						</div>
						<Grid container>
							<Grid item xs={12}>
								<Grid
									container
									spacing={6}
									direction='row'
									className='tabletCenter'>
									{data.map((videoItem, index) => (
										<OnDemandVideo
											index={index}
											linkTo='/idiopathic-hypersomnia/on-demand-video'
											// poster="/images/video1.png"
											title={videoItem.videoTitle}
											// speaker={videoItem.speakerName + ', ' videoItem.speakerTitle}
											desc={videoItem.videoDescription}
											thumbnail={videoItem.thumbnail}
											videoId={videoItem.videoId}
											videoTitle={videoItem.videoTitle}
											videoResource={videoItem.videoResource}
											videoTranscript={videoItem.videoTranscript}
										/>
									))}
								</Grid>
							</Grid>
						</Grid>
					</TabPanel>
				</section>
			</Layout>
		</>
	);
};

export default IhUnbrandedHomePage;
